import { template as template_827934f9ab3f4771b5799153106f9b8a } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_827934f9ab3f4771b5799153106f9b8a(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
